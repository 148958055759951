import { Collapse } from "bootstrap";
(function ($) {
    const activeClass = 'is-shown';
    $('body').on('click', '.js-custom-collapse', function () {
        let target = $(this).attr('data-target');
        if ($(this).hasClass(activeClass)) {
            $(this).removeClass(activeClass);
        } else {
            $(this).addClass(activeClass);
        }
        $('body').find(target).collapse('toggle');
    });
})(jQuery);